import React from 'react';
import _ from 'underscore';
import {hideAlertMessages, postAlertMessage} from '../../js/global/alerts';
import MessageBox from '../../js/global/messagebox';
import Ajax from '../../jskit/general/Ajax';
import Formatter from '../../jskit/general/Formatter';
import URLHistory from '../../jskit/general/URLHistory';
import Utils from '../../jskit/general/Utils';
import Paginator from '../../jskit/react/Paginator';
import {contactGroupsAllowed} from '../common/DevicesCommon';
import TagsController from '../tags/TagsController.jsx';
import BulkContactForm from './BulkContactForm.jsx';
import BulkEscalationsForm from './BulkEscalationsForm.jsx';
import BulkGroupForm from './BulkGroupForm.jsx';
import BulkIntervalForm from './BulkIntervalForm.jsx';
import BulkLocationForm from './BulkLocationForm.jsx';
import BulkMaintenanceForm from './BulkMaintenanceForm.jsx';
import BulkRetryAndSensitivityForm from './BulkRetryAndSensitivityForm.jsx';
import BulkSLAForm from './BulkSLAForm.jsx';
import BulkTagsForm from './BulkTagsForm.jsx';
import BulkVersionForm from './BulkVersionForm.jsx';
import ButtonBar from './ButtonBar.jsx';
import ServiceForm, {Pages} from './ServicesForm.jsx';
import ServiceList from './ServicesList.jsx';

import $ from 'jquery';
import PauseConfirmationForm from '@/devices/services/PauseConfirmationForm';

const SORT_MAP_TRANSLATIONS = _.pairs({
  name: 'cached_ordering',
  monitoring_service_type: 'monitoring_service_type',
  response_time: 'cached_response_time',
});

const DEFAULT_SORT = ['name'];

const MAX_TAGS = 250;

function ensureChecksAreSelectedDecorator(f) {
  return function () {
    if (this.refs.list.noneAreSelected()) {
      this.showNoSelectionError();
      return;
    }

    f.apply(this, arguments);
  };
}

export default class ServicesController extends React.Component {
  constructor(props) {
    super(props);

    this.loadAllData = _.throttle(this.loadAllData, 500);

    this.handleBatchPauseClick = ensureChecksAreSelectedDecorator(this.handleBatchPauseClick);
    this.handleBatchResumeClick = ensureChecksAreSelectedDecorator(this.handleBatchResumeClick);
    this.handleBatchDeleteClick = ensureChecksAreSelectedDecorator(this.handleBatchDeleteClick);
    this.handleBulkIntervalClick = ensureChecksAreSelectedDecorator(this.handleBulkIntervalClick);
    this.handleBulkLocationClick = ensureChecksAreSelectedDecorator(this.handleBulkLocationClick);
    this.handleBulkContactClick = ensureChecksAreSelectedDecorator(this.handleBulkContactClick);
    this.handleBulkEscalationsClick = ensureChecksAreSelectedDecorator(this.handleBulkEscalationsClick);
    this.handleBulkMaintenanceClick = ensureChecksAreSelectedDecorator(this.handleBulkMaintenanceClick);
    this.handleBulkTagsClick = ensureChecksAreSelectedDecorator(this.handleBulkTagsClick);
    this.handleBulkAddToGroupClick = ensureChecksAreSelectedDecorator(this.handleBulkAddToGroupClick);
    this.handleBulkCreateGroupClick = ensureChecksAreSelectedDecorator(this.handleBulkCreateGroupClick);

    Utils.autoBindClass(this);

    this.state = {
      items: [],
      performanceMetrics: {},
      totals: {},
      choices: {},
      contact_choices: {},
      validationErrors: {},
      accountUsage: {},
    };
  }

  componentDidMount() {
    const query = URLHistory.queryStringToObject();
    const pop = this.props.hasWriteAccess ? query.pop : null;
    const goBackOnCancel = query.gobackoncancel ? query.gobackoncancel === 'true' : null;
    URLHistory.replaceState(URLHistory.updateQueryString(null, null, ['pop']));

    URLHistory.addStateChangeListener(
      function (u) {
        this.loadStateFromURL();
      }.bind(this)
    );

    this.loadStateFromURL(true, pop);
    this.setState({goBackOnCancel: goBackOnCancel});
  }

  loadStateFromURL(withChoices, pop) {
    var qs = URLHistory.queryStringToObject();

    if (parseInt(pop) && !qs.s) {
      qs.s = pop;
    }

    if (qs.tags) {
      qs.tags = qs.tags.split(',');
    }

    if (qs.state) {
      qs.state = qs.state.split(',');
    }

    if (qs.sort) {
      qs.sort = qs.sort.split(',');
    }

    if (this.props.group) {
      // we're in Group display mode, so need to remove filters
      qs.monitoring_service_type = undefined;
    }

    this.refs.list.setSort(qs.sort || _.clone(DEFAULT_SORT));
    this.refs.paginator.reset(qs.p);

    if (!_.isEmpty(qs)) {
      // Let ButtonBar restore filters from sessionStorage if not passed via URL
      this.refs.buttonBar.setFiltersAndSearch(qs, qs.s);
    }

    this.setState({}, () => {
      this.loadAllData(withChoices, pop);
    });
  }

  updateStateToURL() {
    const listSort = this.refs.list.getSort();
    const buttonBarQuery = this.refs.buttonBar.getQueryFilter();
    const paginatorPage = this.refs.paginator.getCurrentPage();

    let qs = {};

    if (listSort.toString() !== DEFAULT_SORT.toString()) {
      qs = _.extend(qs, {sort: listSort});
    }

    qs = _.extend(qs, buttonBarQuery.filters);
    qs = _.extend(qs, {s: buttonBarQuery.search});
    qs = _.extend(qs, {p: paginatorPage});
    qs = _.pick(qs, (v) => !!v && v.length !== 0);

    if (qs.tags && qs.tags.length > 0) {
      qs.tags = qs.tags.join(',');
    }

    if (qs.sort && qs.sort.length > 0) {
      qs.sort = qs.sort.join(',');
    }

    URLHistory.replaceState(qs);
  }

  loadAllData(withChoices, pop) {
    this.updateStateToURL();

    var query = {};
    query = _.extend(query, {sort: this.refs.list.getSort()});
    query = _.extend(query, this.refs.buttonBar.getQueryFilter());
    query = _.extend(query, this.refs.paginator.getQueryOffsetLimit());

    if (this.props.group) {
      _.extend(query.filters, {group_id: this.props.group.id});
    }

    // Map sort field names to actual Django model names
    query.sort = query.sort.map((sv) => SORT_MAP_TRANSLATIONS.reduce((v, t) => v.replace(t[0], t[1]), sv));

    return new Ajax().post({
      url: this.props.listURL + (withChoices ? '?choices=1' : ''),
      data: query,
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        this.refs.list.clearSelections();
        this.setState({
          items: data.items,
          performanceMetrics: data.performance_metrics || {},
          totals: data.totals,
          accountUsage: data.account_usage,
        });

        if (data.choices) {
          data.choices = this.transformLoadedChoices(data.choices);
          this.setState({choices: data.choices});
        }
        // `pop` parameter indicates whether we should load an empty
        // form (pop==true), edit a service id (e.g. pop=123) or add
        // a new check of a certain type (eg. pop=TRANSACTION).
        // Can also use `pop=initial` to show the form only
        // if no checks exist.
        if (pop && !(pop === 'initial' && data.items.length > 0)) {
          let itemIndex = null;
          let initialState = null;

          // Flatten list of service types so we can scan it
          const service_types = [].concat.apply([], Object.values(data.choices.monitoring_service_type));

          if (parseInt(pop)) {
            itemIndex = data.items.findIndex((x) => x.id === parseInt(pop));
            itemIndex = itemIndex < 0 ? null : itemIndex;
          } else if (service_types.some((x) => x[0] === pop)) {
            initialState = {monitoring_service_type: pop};
          }

          _.delay(() => this.showEditForm(itemIndex, null, initialState));
        }
      }.bind(this),
    });
  }

  getFormDefaults(choices) {
    var mst = this.refs.buttonBar.getQueryFilter().filters.monitoring_service_type || 'HTTP';
    if (mst === 'CLOUDSTATUS') {
      mst = 'HTTP';
    }

    return {
      contact_groups: _.clone(choices.default_contact),
      monitoring_service_type: mst,
      msp_dns_record_type: choices.msp_dns_record_type[0][0],
      ssl_cert_protocol: choices.ssl_cert_protocol[0][0],
      msp_include_in_global_metrics: true,
      schedule_state: 'ACTIVE',
      schedule_timezone: choices.default_timezone,
    };
  }

  transformLoadedChoices(choices) {
    // Exclude HTTP split fields due to URL parsing
    var excludes = ['msp_port', 'msp_url_scheme', 'msp_url_path'];
    choices.service_defs.HTTP.fields = _.reject(choices.service_defs.HTTP.fields, function (v) {
      return _.contains(excludes, v.field);
    });
    choices.service_defs.PAGESPEED.fields = _.reject(choices.service_defs.PAGESPEED.fields, function (v) {
      return _.contains(excludes, v.field);
    });

    // Map display text of monitoring service type select from service defs
    for (const group in choices.monitoring_service_type) {
      if (group == 'Third Party') {
        delete choices.monitoring_service_type[group];
      } else {
        choices.monitoring_service_type[group] = _.map(choices.monitoring_service_type[group], function (val) {
          return [val[0], choices.service_defs[val[0]].verbose_name];
        });
      }
    }

    return choices;
  }

  extractFormData(item, choices) {
    var type = item.monitoring_service_type;
    var serviceDef = choices.service_defs[type];
    var fieldsToExtract = [
      'id',
      'uuid',
      'name',
      'monitoring_service_type',
      'schedule_state',
      'schedule_timezone',
      'schedule_entries',
      'pause_on_scheduled_maintenance',
    ].concat(_.pluck(serviceDef.fields, 'field'));
    var formData = _.pick(item, fieldsToExtract);
    formData.contact_groups = _.clone(item.contact_group_ids);
    formData.tags = _.clone(item.tag_ids);
    formData.group_check_tags = _.clone(item.group_check_tag_ids);
    formData.schedule_timezone = formData.schedule_timezone || choices.default_timezone;
    formData.escalations = _.map(item.escalations, function (e) {
      return _.extend({}, e, {contact_groups: e.contact_group_ids});
    });
    formData.monitoring_groups = _.clone(item.monitoring_group_ids);
    if (formData.group_response_time_single_check) {
      formData.group_response_time_single_check_data = formData.group_response_time_single_check;
      formData.group_response_time_single_check = formData.group_response_time_single_check.id;
    }

    return formData;
  }

  formWasSaved(ajaxData, formComponent, controllerComponent) {
    // If a RUM check was added, don't hide the form and display the
    // HTML snippet user needs to add to their site.
    const servicesWithSnippets = ['RUM2', 'HEARTBEAT', 'WEBHOOK'];
    const serviceType = formComponent.getFormData().monitoring_service_type;
    if (formComponent.getFormData().id !== ajaxData.data.id && servicesWithSnippets.includes(serviceType)) {
      formComponent.setFormData({id: ajaxData.data.id, uuid: ajaxData.data.uuid});
      if (serviceType === 'RUM2') {
        controllerComponent.state.validationErrors.snippet = 'A new RUM code has been generated for your site.';
      } else {
        controllerComponent.state.validationErrors.snippet =
          'A new ' + Formatter.titleCase(serviceType) + ' URL has been generated for your check.';
      }
      controllerComponent.setState({validationErrors: controllerComponent.state.validationErrors});
      return false;
    }
    return true;
  }

  clearValidationErrors() {
    this.setState({validationErrors: {}});
  }

  showNoSelectionError() {
    MessageBox.alertBox('Please make a selection via the checkboxes below.', 'Make a selection');
  }

  handleFilterChange() {
    this.refs.paginator.reset();
    this.loadAllData();
  }

  handlePageChange() {
    this.loadAllData();
  }

  async handleAddItemClick(e) {
    e.preventDefault();
    await this.loadAllData();
    this.showEditForm(null);
  }

  async handleDuplicateItemClick(itemIndex, e) {
    e.preventDefault();
    await this.loadAllData();
    this.showEditForm(itemIndex, true);
  }

  handleEditItemClick(itemIndex, e) {
    e.preventDefault();
    this.showEditForm(itemIndex, false);
  }

  handleMaintenanceClick(itemIndex, e) {
    e.preventDefault();

    this.showEditForm(itemIndex, false, {}, Pages.PAGE_MAINTENANCE);
  }

  handleDeleteItemClick(itemIndex, e) {
    e.preventDefault();

    var item = this.state.items[itemIndex];
    var msg =
      'Are you sure you want to delete the ' +
      item.monitoring_service_type_display +
      ' check "' +
      (item.name || item.msp_address) +
      '"? ' +
      // eslint-disable-next-line max-len
      'This check and its associated alerts will be permanently deleted and non-recoverable after 30 days. <br /><br />' +
      // eslint-disable-next-line max-len
      '<strong>Note:</strong> Deleting a group check will neither delete its nested checks nor any alerts associated with those.';
    var title = 'Delete Check';

    MessageBox.confirmBox(
      msg,
      title,
      function () {
        new Ajax().post({
          url: this.props.deleteURL,
          data: {id: item.id},
          encoder: 'json',
          decoder: 'json',
          success: function (data) {
            if (data.success) {
              this.refs.form.hideModal();
              this.loadAllData();
            } else {
              MessageBox.validationErrorBox(data);
            }
          }.bind(this),
        });
      }.bind(this)
    );
  }

  handlePauseResume(itemIndexes, action, sendResolvedNotifications) {
    var item = this.state.items[itemIndexes[0]];
    new Ajax().post({
      url: this.props.pauseResumeURL,
      data: {item: [item.id], action, send_resolved_notifications: sendResolvedNotifications},
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        this.refs.pauseConfirmationForm.hideModal();
        if (data.success) {
          this.loadAllData();
        } else {
          MessageBox.validationErrorBox(data);
        }
      }.bind(this),
    });
  }

  handlePauseResumeItemClick(itemIndex, action, e) {
    e.preventDefault();
    const item = this.state.items[itemIndex];
    const isUp = item.cached_state_is_up;
    if (!isUp && action === 'pause') {
      return this.refs.pauseConfirmationForm.showModal([itemIndex], true);
    }
    return this.handlePauseResume([itemIndex], action);
  }

  showEditForm(itemIndex, duplicate, initialState, currentPage) {
    let state;

    this.clearValidationErrors();
    hideAlertMessages();

    if (null === itemIndex) {
      state = Object.assign({}, this.getFormDefaults(this.state.choices), initialState);
    } else {
      state = this.extractFormData(_.clone(this.state.items[itemIndex]), this.state.choices);
      if (duplicate) {
        state.id = null;
      }
    }

    this.refs.form.showModal(null === itemIndex, state, currentPage);
  }

  handleFormSaveClick(callback) {
    this.clearValidationErrors();
    this.handleFormSubmit(callback);
  }

  handleFormSubmit(callback) {
    const formData = _.clone(this.refs.form.getFormData());
    // if in group mode - set group id
    if (this.props.group) {
      formData.group_id = this.props.group.id;
    }

    // if saving group check in averaging mode - clear contact groups
    if (!contactGroupsAllowed(formData)) {
      formData.contact_groups = [];
    }

    new Ajax().post({
      url: this.props.saveURL,
      disable: '.modal button',
      data: formData,
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (data.success) {
          const serviceDef = this.state.choices.service_defs[formData.monitoring_service_type];
          var mst_name = serviceDef.verbose_name;
          var msg = `Your ${mst_name} check will commence monitoring within 5 minutes.`;
          const isCustomCheck = ['HEARTBEAT', 'WEBHOOK'].includes(formData.monitoring_service_type);
          const isGroupCheck = formData.monitoring_service_type === 'GROUP';
          if (isGroupCheck) {
            msg = `Your ${mst_name} check is ready to monitor associated checks.`;
          } else if (serviceDef.force_interval === 0 || isCustomCheck) {
            msg = `Your ${mst_name} check is ready to receive data.`;
          } else if (serviceDef.force_interval === 60) {
            msg = `Your ${mst_name} check will commence monitoring hourly.`;
          } else if (serviceDef.force_interval !== null) {
            msg = `Your ${mst_name} check will commence monitoring daily.`;
          }
          if (this.state.goBackOnCancel) {
            msg += ` Go back to <a href="${this.props.launchpadURL}">Launchpad.</a>`;
          }

          const checkWasAdded = this.refs.form.getFormData().id !== data.data.id;
          const formWasSaved = this.formWasSaved(data, this.refs.form, this); //has side effect on formData so order of these 2 lines is important

          if (formWasSaved) {
            this.refs.form.hideModal();
          } else {
            this.refs.form.goToFirstPage();
          }

          if (checkWasAdded) {
            postAlertMessage(msg, 'success', true);
          }

          const filters = this.refs.buttonBar.getQueryFilter();
          if (checkWasAdded && !filters.search && _.isEmpty(filters.filters)) {
            this.refs.list.setSort(['-created_at']);
            this.handleFilterChange();
          } else {
            this.loadAllData();
          }
          if (callback) {
            callback(data.data);
          }
        } else {
          this.setState({validationErrors: data.fields});
        }
      }.bind(this),
    });
  }

  handleItemChecked(e) {
    // Re-render the button bar's num selected box
    this.forceUpdate();
  }

  handleBatchPauseClick(itemIndex, action, sendResolvedNotifications) {
    this.doBulkFormSubmission(this.props.batchPauseResumeURL, {
      action: 'pause',
      send_resolved_notifications: sendResolvedNotifications,
    });
  }

  handleBatchResumeClick(e) {
    if (e !== undefined) {
      e.preventDefault();
    }
    this.doBulkFormSubmission(this.props.batchPauseResumeURL, {action: 'resume'});
  }

  handleBatchDeleteClick(e) {
    e.preventDefault();

    const totalChecked = this.refs.list.getSelectedIndices().length;
    const msg =
      'Are you sure you want to delete ' +
      totalChecked +
      ' checks? ' +
      'Checks and associated alerts will be permanently deleted and ' +
      'non-recoverable after 30 days. <br /><br />' +
      '<strong>Note:</strong> Deleting a group check will neither delete its nested ' +
      'checks nor any alerts associated with those.';
    const title = 'Delete Multiple Checks';

    MessageBox.confirmBox(
      msg,
      title,
      function () {
        this.doBulkFormSubmission(this.props.batchDeleteURL);
      }.bind(this)
    );
  }

  handleBulkIntervalClick(e) {
    e.preventDefault();
    this.refs.bulkIntervalForm.showModal();
  }

  handleBulkLocationClick(e) {
    e.preventDefault();
    this.refs.bulkLocationForm.showModal();
  }

  handleBulkContactClick(e) {
    e.preventDefault();
    this.refs.bulkContactForm.showModal();
  }

  handleBulkEscalationsClick(e) {
    e.preventDefault();
    this.refs.bulkEscalationsForm.showModal();
  }

  handleRetryAndSensitivityClick(e) {
    e.preventDefault();
    this.refs.bulkRetryAndSensitivityForm.showModal();
  }

  handleBulkSLAClick(e) {
    e.preventDefault();
    this.refs.bulkSLAForm.showModal();
  }

  handleBulkVersionClick(e) {
    e.preventDefault();
    this.refs.bulkVersionForm.showModal();
  }

  handleBulkMaintenanceClick(e) {
    e.preventDefault();
    this.refs.bulkMaintenanceForm.showModal();
  }

  handleBulkTagsClick(e) {
    e.preventDefault();
    this.refs.bulkTagsForm.showModal();
  }

  handleBulkCreateGroupClick(e) {
    e.preventDefault();
    const selectedIndices = _.clone(this.refs.list.getSelectedIndices());
    const selectedItems = _.map(
      _.filter(_.pick(this.state.items, selectedIndices), (s) => s.monitoring_service_type !== 'GROUP'),
      (item) => ({
        id: item.id,
        name: item.name || item.msp_address,
        type: item.monitoring_service_type_display,
        address: item.name ? item.msp_address : '',
      })
    );
    const initialState = {monitoring_service_type: 'GROUP', group_check_services: selectedItems};
    this.showEditForm(null, false, initialState);
  }

  handleBulkAddToGroupClick(e) {
    e.preventDefault();
    this.refs.bulkAddToGroupForm.showModal();
  }

  handleBulkAddGroupFormSaveClick(e) {
    e.preventDefault();
    const formData = this.refs.bulkAddToGroupForm.serialize();
    const groupId = formData.group_id;
    const callbackFn = () => {
      postAlertMessage('The selected checks have been successfully added to this group check.', 'success', true);
    };
    if (groupId === 'new') {
      this.refs.bulkAddToGroupForm.hideModal();
      this.handleBulkCreateGroupClick(e);
    } else {
      this.doBulkFormSubmission(this.props.batchAddToGroupURL, formData, this.refs.bulkAddToGroupForm, callbackFn);
    }
  }

  handleBulkIntervalFormSaveClick(e) {
    e.preventDefault();
    this.doBulkFormSubmission(
      this.props.batchUpdateIntervalURL,
      this.refs.bulkIntervalForm.serialize(),
      this.refs.bulkIntervalForm
    );
  }

  handleBulkLocationFormSaveClick(e) {
    e.preventDefault();
    this.doBulkFormSubmission(
      this.props.batchUpdateLocationsURL,
      this.refs.bulkLocationForm.serialize(),
      this.refs.bulkLocationForm
    );
  }

  handleBulkContactFormSaveClick(e) {
    e.preventDefault();
    this.doBulkFormSubmission(
      this.props.batchUpdateContactsURL,
      this.refs.bulkContactForm.serialize(),
      this.refs.bulkContactForm
    );
  }

  handleBulkEscalationsFormSaveClick(e) {
    e.preventDefault();
    this.doBulkFormSubmission(
      this.props.batchUpdateEscalationsURL,
      {escalations: this.refs.bulkEscalationsForm.serialize()},
      this.refs.bulkEscalationsForm
    );
  }

  handleBulkMaintenanceFormSaveClick(e) {
    e.preventDefault();
    this.doBulkFormSubmission(
      this.props.batchUpdateMaintenanceURL,
      {maintenance: this.refs.bulkMaintenanceForm.serialize()},
      this.refs.bulkMaintenanceForm
    );
  }

  handleBulkRetryAndSensitivityFormSaveClick(e) {
    e.preventDefault();
    this.doBulkFormSubmission(
      this.props.batchUpdateRetryAndSensitivityURL,
      {retryAndSensitivity: this.refs.bulkRetryAndSensitivityForm.serialize()},
      this.refs.bulkRetryAndSensitivityForm
    );
  }

  handleBulkSLAFormSaveClick(e) {
    e.preventDefault();
    this.doBulkFormSubmission(
      this.props.batchUpdateSLAURL,
      {sla: this.refs.bulkSLAForm.serialize()},
      this.refs.bulkSLAForm
    );
  }

  handleBulkVersionFormSaveClick(e) {
    e.preventDefault();
    this.doBulkFormSubmission(
      this.props.batchUpdateVersionURL,
      this.refs.bulkVersionForm.serialize(),
      this.refs.bulkVersionForm
    );
  }

  handleBulkTagsFormSaveClick(e) {
    e.preventDefault();
    this.doBulkFormSubmission(
      this.props.batchUpdateTagsURL,
      this.refs.bulkTagsForm.serialize(),
      this.refs.bulkTagsForm
    );
  }

  handleBulkTagsFormCreateTagsClick(e) {
    e.preventDefault();
    this.refs.bulkTagsForm.hideModal();

    // Need a delay to overcome a bootstrap bug where the
    // scrollbar doesn't work on Manage Tags popup
    setTimeout(() => this.handleManageTagsClick(e), 500);
  }

  doBulkFormSubmission(submitURL, data, formRef, successCallbackFn) {
    if (this.refs.list.noneAreSelected()) {
      return;
    }

    var selectedIndices = this.refs.list.getSelectedIndices();
    var selectedItems = _.pick(this.state.items, selectedIndices);
    var selectedItemIds = _.pluck(selectedItems, 'id');

    new Ajax().post({
      url: submitURL,
      data: $.extend({item: selectedItemIds}, data),
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (data.success) {
          this.loadAllData();
          if (formRef) {
            formRef.hideModal();
          }
          if (successCallbackFn) {
            successCallbackFn();
          }
        } else {
          MessageBox.validationErrorBox(data);
        }
      }.bind(this),
    });
  }

  handleManageTagsClick(e) {
    e.preventDefault();
    this.refs.manageTagsController.showModal(this.state.choices.tags, MAX_TAGS);
  }

  handleManageTagsSaved() {
    this.refs.manageTagsController.hideModal();
    this.loadAllData(true);
  }

  render() {
    var numSelected = this.refs.list && this.refs.list.numSelected();
    var selectedIndices = (this.refs.list && this.refs.list.getSelectedIndices()) || [];
    var selectedItems = (this.refs.list && _.pick(this.state.items, selectedIndices)) || [];
    var extraFormProps = {
      dnsCheckURL: this.props.dnsCheckURL,
      whoisCheckURL: this.props.whoisCheckURL,
      getContactsListURL: this.props.getContactsListURL,
      probeServersListURL: this.props.probeServersListURL,
      domainHealthURL: this.props.domainHealthURL,
      runTestURL: this.props.runTestURL,
      transactionRecorderURL: this.props.transactionRecorderURL,
      servicesCheckListURL: this.props.servicesCheckListURL,
      pagespeedReportURL: this.props.pagespeedReportURL,
    };

    var extraListProps = {
      alertsURL: this.props.alertsURL,
      defaultDrillDownURL: this.props.defaultDrillDownURL,
      analysisURL: this.props.analysisURL,
      drillDownURLs: this.props.drillDownURLs,
      auditLogURL: this.props.auditLogURL,
      transactionRecorderURL: this.props.transactionRecorderURL,
      groupCheckInfo: this.props.group,
      pagespeedReportURL: this.props.pagespeedReportURL,
    };

    return (
      <React.Fragment>
        <ButtonBar
          ref="buttonBar"
          hasWriteAccess={this.props.hasWriteAccess}
          typesFilter={this.state.choices.monitoring_service_type}
          tags={this.state.choices.tags}
          maxTags={MAX_TAGS}
          totals={this.state.totals}
          numSelected={numSelected}
          selectedItems={selectedItems}
          onFilterChange={this.handleFilterChange}
          onSelectAllClick={this.handleSelectAllClick}
          onPauseClick={this.handleBatchPauseClick}
          onResumeClick={this.handleBatchResumeClick}
          onDeleteClick={this.handleBatchDeleteClick}
          onBulkIntervalClick={this.handleBulkIntervalClick}
          onBulkLocationClick={this.handleBulkLocationClick}
          onBulkContactClick={this.handleBulkContactClick}
          onBulkEscalationsClick={this.handleBulkEscalationsClick}
          onRetryAndSensitivityClick={this.handleRetryAndSensitivityClick}
          onBulkSLAClick={this.handleBulkSLAClick}
          onBulkVersionClick={this.handleBulkVersionClick}
          onBulkMaintenanceClick={this.handleBulkMaintenanceClick}
          onBulkTagsClick={this.handleBulkTagsClick}
          onBulkCreateGroupClick={this.handleBulkCreateGroupClick}
          onBulkAddToGroupClick={this.handleBulkAddToGroupClick}
          onManageTagsClick={this.handleManageTagsClick}
          onAddClick={this.handleAddItemClick}
          bulkImportURL={this.props.bulkImportURL}
          domainHealthURL={this.props.domainHealthURL}
          defaultDrillDownURL={this.props.defaultDrillDownURL}
          groupCheckInfo={this.props.group}
        />
        <ServiceList
          ref="list"
          hasWriteAccess={this.props.hasWriteAccess}
          hasAdminAccess={this.props.hasAdminAccess}
          hasCreatedChecks={this.props.hasCreatedChecks}
          hasLoaded={!_.isEmpty(this.state.choices)}
          items={this.state.items}
          performanceMetrics={this.state.performanceMetrics}
          serviceDefs={this.state.choices.service_defs}
          extraListProps={extraListProps}
          onAddCheck={this.handleAddItemClick}
          onPauseResume={this.handlePauseResumeItemClick}
          onSort={this.handleFilterChange}
          onCheck={this.handleItemChecked}
          onEdit={this.handleEditItemClick}
          onMaintenance={this.handleMaintenanceClick}
          onDuplicate={this.handleDuplicateItemClick}
          onDelete={this.handleDeleteItemClick}
        />
        <Paginator
          ref="paginator"
          pageSize={this.props.pageSize}
          totalRecords={this.state.totals.all || 0}
          onPageChange={this.handlePageChange}
        />

        <ServiceForm
          ref="form"
          choices={this.state.choices}
          accountUsage={this.state.accountUsage}
          checkFrequency={this.props.checkFrequency}
          errors={this.state.validationErrors}
          extraFormProps={extraFormProps}
          resetValidationErrorsFn={this.clearValidationErrors}
          contactUsURL={this.props.contactUsURL}
          invoicesURL={this.props.invoicesURL}
          purchaseURL={this.props.purchaseURL}
          manageSubscriptionURL={this.props.manageSubscriptionURL}
          manageAddOnsURL={this.props.manageAddOnsURL}
          purchaseRequestURL={this.props.purchaseRequestURL}
          userCanPurchase={this.props.userCanPurchase}
          isPrimary={this.props.isPrimary}
          accountCanPurchase={this.props.accountCanPurchase}
          goBackOnCancel={this.state.goBackOnCancel}
          onSave={this.handleFormSaveClick}
          stepDefs={this.props.stepDefs}
          plan={this.props.plan}
          limits={this.props.limits}
          hasTransactionExtEnabled={this.props.hasTransactionExtEnabled}
          featureFlags={this.props.featureFlags}
        />

        <BulkIntervalForm
          ref="bulkIntervalForm"
          choices={this.state.choices}
          onSave={this.handleBulkIntervalFormSaveClick}
        />
        <BulkLocationForm
          ref="bulkLocationForm"
          choices={this.state.choices}
          onSave={this.handleBulkLocationFormSaveClick}
        />
        <BulkContactForm
          ref="bulkContactForm"
          choices={this.state.choices}
          onSave={this.handleBulkContactFormSaveClick}
        />
        <BulkEscalationsForm
          ref="bulkEscalationsForm"
          choices={this.state.choices}
          onSave={this.handleBulkEscalationsFormSaveClick}
        />
        <BulkMaintenanceForm
          ref="bulkMaintenanceForm"
          choices={this.state.choices}
          onSave={this.handleBulkMaintenanceFormSaveClick}
          featureFlags={this.props.featureFlags}
        />
        <BulkRetryAndSensitivityForm
          ref="bulkRetryAndSensitivityForm"
          choices={this.state.choices}
          onSave={this.handleBulkRetryAndSensitivityFormSaveClick}
        />
        <BulkSLAForm ref="bulkSLAForm" choices={this.state.choices} onSave={this.handleBulkSLAFormSaveClick} />
        <BulkVersionForm
          ref="bulkVersionForm"
          choices={this.state.choices}
          onSave={this.handleBulkVersionFormSaveClick}
        />
        <BulkTagsForm
          ref="bulkTagsForm"
          choices={this.state.choices}
          onSave={this.handleBulkTagsFormSaveClick}
          onCreateTags={this.handleBulkTagsFormCreateTagsClick}
        />
        <BulkGroupForm
          ref="bulkAddToGroupForm"
          groupChecksListURL={this.props.servicesCheckListURL + '?monitoring_service_type=GROUP'}
          onSave={this.handleBulkAddGroupFormSaveClick}
          onCreateGroup={this.handleBulkCreateGroupClick}
        />
        <PauseConfirmationForm ref="pauseConfirmationForm" onSave={this.handlePauseResume} isBulk={false} />
        <TagsController
          ref="manageTagsController"
          saveURL={this.props.manageTagsURL}
          onSaved={this.handleManageTagsSaved}
        />
      </React.Fragment>
    );
  }
}
